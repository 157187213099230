<template>
  <div class="flex flex-col">
    <div v-if="!!title" class="flex items-start justify-between">
      <BlockTitle :subtitle :title />

      <RevButton
        v-if="hasCta"
        class="hidden md:block"
        :icon="IconArrowRight"
        size="small"
        variant="primary"
      >
        {{ cta?.label }}
      </RevButton>
    </div>

    <slot />

    <div v-if="hasCta" class="mt-16 flex grow justify-end md:hidden">
      <RevButton
        v-if="hasCta"
        :icon="IconArrowRight"
        size="small"
        variant="primary"
      >
        {{ cta?.label }}
      </RevButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type { CTALink } from '@backmarket/http-api/src/api-specs-content/models/cta-link'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevButton } from '@ds/components/Button'
import { IconArrowRight } from '@ds/icons/IconArrowRight'

import BlockTitle from '../../shared-components/BlockTitle/BlockTitle.vue'

const props = defineProps<{
  title?: string
  subtitle?: string
  cta?: CTALink
}>()

const hasCta = computed(() => !isEmpty(props.cta))
</script>
